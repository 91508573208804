const Danger = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="Group_30948"
        data-name="Group 30948"
        transform="translate(-333 -568)"
      >
        <g id="Alerts" transform="translate(333 568)">
          <g
            id="Rectangle_6211"
            data-name="Rectangle 6211"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
            opacity="0"
          >
            <rect width="16" height="16" stroke="none" />
            <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
          </g>
          <path
            id="Union_57"
            data-name="Union 57"
            d="M-15518.316-24777.947a1.677,1.677,0,0,1-1.443-.834,1.668,1.668,0,0,1-.037-1.668l5.369-9.7a1.657,1.657,0,0,1,1.475-.852,1.66,1.66,0,0,1,1.481.852l5.369,9.7a1.668,1.668,0,0,1-.037,1.668,1.671,1.671,0,0,1-1.444.834Zm5.016-11.578-5.37,9.693a.42.42,0,0,0-.006.41.4.4,0,0,0,.36.193h10.732a.391.391,0,0,0,.361-.193.42.42,0,0,0-.006-.41l-5.37-9.693a.416.416,0,0,0-.354-.225A.407.407,0,0,0-15513.3-24789.525Zm-.467,8.684a.818.818,0,0,1,.814-.814.819.819,0,0,1,.821.814.82.82,0,0,1-.821.816A.818.818,0,0,1-15513.768-24780.842Zm.36-1.8a.665.665,0,0,1-.187-.455v-3.465a.644.644,0,0,1,.635-.652h.006a.642.642,0,0,1,.454.186.659.659,0,0,1,.193.467v3.465a.645.645,0,0,1-.647.641A.632.632,0,0,1-15513.407-24782.639Z"
            transform="translate(15520.949 24792.473)"
            fill="#f02833"
          />
        </g>
      </g>
    </svg>
  );
  
  export default Danger;