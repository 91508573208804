import StartProcess from "@/components/DTMF/icons/StartProcess";

const DtmfStartProcessButton = ({ name, handleStart, required, active }) => {
  return (
    <div className="w-full mx-auto mb-4">
      <label
        htmlFor="fullName"
        className="block text-xs font-medium text-black-100 py-1"
      >
        {`${name}${required ? "*" : ""}`}
      </label>
      <button
        id={name}
        onClick={handleStart}
        className="font-bold py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline flex items-center justify-center space-x-2 border border-gray-300 hover:bg-gray-50 w-full bg-darkblue-100hover:bg-darkblue-200hover:border-darkblue-350/[.3]borderborder-darkblue-200dark:border-darkblue-200dark:text-darkblue-200text-darkblue-350dark:bg-darkblue-100dark:hover:bg-darkblue-100/[.25]font-boldpy-2px-3rounded-lgfocus:outline-nonefocus:shadow-outlineflexitems-centerjustify-centerspace-x-2border-gray-300hover:bg-gray-50w-fulldark:bg-darkblue-100dark:hover:bg-darkblue-100/[.25]dark:border-darkblue-200dark:text-darkblue-200"
      >
        <StartProcess active={active} />
        <span className={active ? "text-orange-500" : "text-darkblue-300"}>
          Start Process
        </span>
      </button>
    </div>
  );
};

export default DtmfStartProcessButton