import { useState, useEffect } from "react";
import { Steps, Row, Col, Affix, Button, Modal } from "antd";
import {
  CloudSyncOutlined,
  CloudDownloadOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { useAppContext } from "../context/appContext";
import styles from "../styles/Home.module.css";
import IntelTableModal from "./IntelTableModal";

export default function StepsApp() {
  const { messageData, scripterData, setIsVisibleIntTable } = useAppContext();
  const [connectedStatus, setConnectedStatus] = useState("wait");
  const [affixHandle, setAffixHandle] = useState(false);
  
  useEffect(() => {
    setConnectedStatus(messageData.type);

    // const updateMessage = async () => {
    //   return await messageData;
    // };

    // updateMessage()
    //   .then((data) => {
    //     setConnectedStatus(data.type)
    //   })
    //   .catch((error) => {
    //     console.log("Stepper update message error ", error);
    //   });
  }, [messageData]);

  let stepperStyle = {
    height: "6vh",
    backgroundColor: affixHandle ? "#fff" : "",
  };

  const handleClick = ( ) => {
    setIsVisibleIntTable(true);
  }

  return (
    <>
    <Affix onChange={(affixed) => setAffixHandle(affixed)}>
      <div>
      {
        (scripterData?.["intelepeer-uui"] && messageData?.type !== "destroyed") &&  
        <Row justify={"center"} align={"middle"} style={stepperStyle}>
          <Button onClick={handleClick} className={styles.vipButton}>
          IVA Call  
          </Button>
        </Row>
       }
      <Row justify={"center"} align={"middle"} style={stepperStyle}>
        <Col xs={22} sm={23} md={20} lg={10}>
          <Steps
            responsive={false}
            items={[
              {
                title: "Connected",
                status:
                  connectedStatus == "connected" ||
                  connectedStatus == "disconnected" ||
                  connectedStatus == "destroyed"
                    ? "finish"
                    : "wait",
                icon: <CloudSyncOutlined />,
              },
              {
                title: "Disconnected",
                status:
                  connectedStatus == "disconnected" ||
                  connectedStatus == "destroyed"
                    ? "finish"
                    : "wait",
                icon: <ApiOutlined />,
              },
              {
                title: "Destroyed",
                status: connectedStatus == "destroyed" ? "finish" : "wait",
                icon: <CloudDownloadOutlined />,
              },
            ]}
          />
        </Col>
      </Row>
      </div>
    </Affix>
    <IntelTableModal />
    </>
  );
}
