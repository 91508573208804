import { Button } from "@/components/DTMF/button";
import Image from "next/image";
import { useAppContext } from "@/context/appContext";

const DTMFSubmitStatus = ({ restartDtmf }) => {
  const { dtmfState } = useAppContext();

  let status = dtmfState.status;

  let statusText = dtmfState.statusText;

  return (
    <>
      {status == "success" ? (
        <div className="flex flex-1 flex-col p-4 mt-8 lg:h-[50vh] lg:mt-0">
          <div className="flex flex-1 flex-col items-center justify-center text-center">
            <Image
              src={`/images/dtmf-success.svg`}
              alt="Logo"
              width="300"
              height="116"
              priority
            />
            <div className="text-sm text-green-500">Form Sent</div>
            <div className="text-sm text-darkblue-300 dark:text-white mt-2">
              {statusText}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-1 flex-col p-4 mt-8 lg:h-[46vh] lg:mt-0 justify-between">
            <div className="flex flex-col items-center justify-center text-center flex-grow">
              <Image
                src={`/images/dtmf-fail.svg`}
                alt="Logo"
                width="300"
                height="116"
                priority
              />
              <div className="text-sm text-red-500">Form Sent</div>
              <div className="text-sm text-darkblue-300 dark:text-white mt-2">
                {statusText}
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-2 pt-4 pr-4 pl-4">
            <Button
              className="flex-1"
              onClick={(e) => restartDtmf(e)}
              variant="default"
            >
              Restart
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default DTMFSubmitStatus;
