import RestartProcess from "@/components/DTMF/icons/RestartProcess";

const DtmfRestartButton = ({ handleFunction }) => {
  return (
    <div className="w-1/2 mx-auto mb-2">
      <button
        onClick={handleFunction}
        className={
          "bg-darkblue-100hover:bg-darkblue-200hover:border-darkblue-350/[.3]borderborder-darkblue-200dark:border-darkblue-200dark:text-darkblue-200text-darkblue-350dark:bg-darkblue-100dark:hover:bg-darkblue-100/[.25]font-boldpy-2px-3rounded-lgfocus:outline-nonefocus:shadow-outlineflexitems-centerjustify-centerspace-x-2border-gray-300hover:bg-gray-50w-fulldark:bg-darkblue-100dark:hover:bg-darkblue-100/[.25]dark:border-darkblue-200dark:text-darkblue-200 font-bold py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline flex items-center justify-center space-x-2 border border-gray-300 hover:bg-gray-50 w-full"
        }
      >
        {
          <>
            <RestartProcess />
            <span className="text-darkblue-300">restart</span>
          </>
        }
      </button>
    </div>
  );
};

export default DtmfRestartButton