import Image from "next/image"
import { cn } from "@/utils/cn";

const PlaceholderText = ({
  className,
  title,
  description,
  imageSrc,
  contentTextAllign = "text-center",
  opacity = true,
}) => {
  return (
    <div
      className={cn(
        `mx-auto flex w-full flex-col items-center gap-6`,
        className
      )}
    >
      {imageSrc && (
        <span className="flex justify-self-center">
          <Image src={imageSrc} alt="Logo" width={194} height={160} priority />
        </span>
      )}
      <div
        className={`w-full rounded-sm ${
          opacity && !imageSrc && "bg-opacity bg-darkblue-300/[.03]"
        } p-2 ${contentTextAllign}`}
      >
        {title && (
          <div className="pb-4 text-base font-bold text-darkblue-500 dark:text-white">
            {title}
          </div>
        )}
        {description && (
          <p className="text-xs leading-4 text-darkblue-300">{description}</p>
        )}
      </div>
    </div>
  );
};

export default PlaceholderText