import { Modal, Spin } from "antd";

import { Button } from "@/components/DTMF/button";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import { useAppContext } from "@/context/appContext";
import axios from "axios";
import dynamic from "next/dynamic";
import { flushSync } from "react-dom";
import "dayjs/locale/en-gb";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const DatePicker = dynamic(() =>
  import("@mui/x-date-pickers/DatePicker").then((mod) => mod.DatePicker)
);
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Image from "next/image";

import DTMFSubmitStatus from "@/components/DTMF/DTMFSubmitStatus";
import DtmfConfirmButton from "@/components/DTMF/DtmfConfirmButton";
import DtmfInputField from "@/components/DTMF/DtmfInputField";
import DtmfRestartButton from "@/components/DTMF/DtmfRestartButton";
import DtmfStartProcessButton from "@/components/DTMF/DtmfStartProcessButton";
import PlaceholderText from "@/components/DTMF/PlaceholderText";



const DTMFModal = () => {
  const { openDTMFModal, setOpenDTMFModal, dtmfState, setDtmfState, cookies: { tenant, session: userSession } } = useAppContext();
  const [isLoading, setIsLoading] = useState(true)
  const { theme } = useTheme();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formData, setFormData] = useState([]);
  const [dtmfTagSet, setDtmfTagSet] = useState(false);

  useEffect(() => {
      setFormData(dtmfState);
  }, []);

  useEffect(() => {
    if (dtmfState?.data?.length > 0) {
      setIsLoading(false);
    }
    setFormData(dtmfState);
  }, [dtmfState]);

  const handleOk = () => {
    setOpenDTMFModal(false);
    flushSync(() => {
      setFormData(prev => []);
      setDtmfState({
        open: false,
        ack: null,
        status: null,
        statusText: "",
        data: [],
        selectedInput: null,
      })
    });
  };

  const handleCancel = () => {
    setOpenDTMFModal(false);
    flushSync(() => {
      setFormData(prev => []);
      setDtmfState({
        open: false,
        ack: null,
        status: null,
        statusText: "",
        data: [],
        selectedInput: null,
      })

    });
  };



  const selectedInput = dtmfState.selectedInput;
  const ack = dtmfState.ack;

  let statusText = dtmfState.statusText

  const darkTheme = createTheme({
      palette: {
          mode: theme
      },
  });

  const handleChange = (value, id, type) => {
    if (!id) return;

    if (type === "number") {
      const numberRegex = /^[0-9\b]+$/;
      if (value !== "" && !numberRegex.test(value)) {
        return;
      }
    }

    let newData = formData.data;
    let index = newData.findIndex((e) => e.id === id);
    if (index != -1) {
      newData[index].value = value;
    }
    flushSync(() => {
      setDtmfState((prev) => ({
        ...prev,
        data: newData,
      }));
    });
  };

  const handleStart = async (id) => {
    if (!id) return;
    if (buttonDisabled) return;
    setButtonDisabled(true);

    axios({
      url: "/api/dtmf/set-dtmf-status",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        tenant: tenant,
        session: userSession,
        activecallkey: ack,
        status: "active",
        id: id,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setDtmfState((prev) => ({
            ...prev,
            selectedInput: id,
          }));
        }
      })
      .finally(() => {
        setButtonDisabled(false);
      })
      .catch((err) => {
        console.error(err);
        axios({
          method: "POST",
          url: "/api/gcpLogger",
          data: {
            gcpData: {
              success: false,
              data: err?.response?.data,
              url: err?.config?.url,
              status: err?.response?.status || 408,
              message: err?.message,
              ack: ack
            },
            message: `productAPI/set-dtmf-status-active`
          }
        })  
      });

    if (!dtmfTagSet) {
      setDtmfTagSet(true)
      try {
        await axios({
          url: "/api/setActiveCallTag",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            session: userSession,
            key: ack,
            tags: ["ahRzfm11c3RlcmktaGl6bWV0bGVyaXIYCxILQ2FsbFRhZ0xpc3QYgICyq9nkwQgMogElZXBlcmZvcm1heC1hcnJpYS5jYWxsY2VudGVyc3R1ZGlvLmNvbQ"]
          },
        })
      } catch (err) {
        console.error(err);
        console.log(err?.message);
      }
    }
  };

  const restartDtmf = (e) => {
    e.preventDefault();
    flushSync(() => {
      setDtmfState((prev) => ({
        ...prev,
          status: null,
      }));
    });
  };

  const handleComplete = (id) => {
    if (!id) return;
    if (buttonDisabled) return;
    setButtonDisabled(true);

    axios({
      url: "/api/dtmf/set-dtmf-status",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        tenant: tenant,
        session: userSession,
        activecallkey: ack,
        status: "done",
        id: id,
      },
    })
      .then((response) => {
        if (response.data.success) {
          let newData = formData.data;
          let index = newData.findIndex((e) => e.id === id);
          if (index != -1) {
            newData[index].completed = true;
            if (response.data.data.valid) {
              newData[index].valid = true;
              newData[index].validText = "Valid";
            } else if (response.data.data.valid === false) {
              newData[index].valid = false;
              newData[index].validText = response.data.data.message;
            }
          }
          flushSync(() => {
            setDtmfState((prev) => ({
              ...prev,
              selectedInput: null,
              data: newData,
            }));
          });
        }
      })
      .finally(() => {
        setButtonDisabled(false);
      })
      .catch((err) => {
        console.error(err);
        axios({
          method: "POST",
          url: "/api/gcpLogger",
          data: {
            gcpData: {
              success: false,
              data: err?.response?.data,
              url: err?.config?.url,
              status: err?.response?.status || 408,
              message: err?.message,
              ack: ack
            },
            message: `productAPI/set-dtmf-status-done`
          }
        })  
      });
  };

  const handleRestart = (id) => {
    if (!id) return;
    if (buttonDisabled) return;
    setButtonDisabled(true);

    axios({
      url: "/api/dtmf/set-dtmf-status",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        tenant: tenant,
        session: userSession,
        activecallkey: ack,
        status: "reset",
        id: id,
      },
    })
      .then((response) => {
        if (response.data.success) {
          let newData = formData.data;
          let index = newData.findIndex((e) => e.id === id);
          if (index != -1) {
            newData[index].value = "";
            newData[index].completed = false;
            newData[index].valid = null;
            newData[index].validText = "";
          }
          if (selectedInput == id) {
            flushSync(() => {
              setDtmfState((prev) => ({
                ...prev,
                  selectedInput: id,
                  data: newData,
              }));
            });
          } else {
            flushSync(() => {
              setDtmfState((prev) => ({
                ...prev,
                  selectedInput: selectedInput,
                  data: newData,
              }));
            });
          }
        }
      })
      .finally(() => {
        setButtonDisabled(false);
      })
      .catch((err) => {
        console.error(err);
        axios({
          method: "POST",
          url: "/api/gcpLogger",
          data: {
            gcpData: {
              success: false,
              data: err?.response?.data,
              url: err?.config?.url,
              status: err?.response?.status || 408,
              message: err?.message,
              ack: ack
            },
            message: `productAPI/set-dtmf-status-reset`
          }
        })  
      });
  };

  const submitDtmf = (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    setButtonDisabled(true);

    let submitData = formData.data
      .filter((e) => e.editable || e.static)
      .map((e) => {
        return {
          variable_name: e.id,
          value:
            e.type === "date" && e.value ? e.value.format("MM-YYYY") : e.value,
        };
      });
    axios({
      url: "/api/dtmf/submit-dtmf-form",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        submitData: submitData,
        activecallkey: ack,
        session: userSession,
        tenant: tenant,
      },
    })
      .then((response) => {
        if (response.data.data.success) {
          //setAlertSuccess(true);

          flushSync(() => {
            setDtmfState((prev) => ({
              ...prev,
                status: "success",
                statusText: "Successfully submitted",
                data: [],
            }));
          });
        } else {
          flushSync(() => {
            setDtmfState((prev) => ({
              ...prev,
                status: "fail",
                statusText:
                  "The form failed to submit. Check the inputs and try again.",
            }));
          });
        }
      })
      .finally(() => {
        setButtonDisabled(false);
      })
      .catch((err) => {
        console.error(err);
        axios({
          method: "POST",
          url: "/api/gcpLogger",
          data: {
            gcpData: {
              success: false,
              data: err?.response?.data,
              url: err?.config?.url,
              status: err?.response?.status || 408,
              message: err?.message,
              ack: ack
            },
            message: `productAPI/submit-dtmf-form`
          }
        })  
      });
  };



  return (
    <>
      <Modal
        title="Customer Card Form"
        open={openDTMFModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={null}
        closable={true}
        maskClosable={false}
      >
        {
          isLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin/>
            </div> 
          )
          : <>
        {!(formData?.status) ? (
        <>
          <div className="flex flex-1 flex-col p-4 pb-0">
            <div className="rounded border border-darkblue-200 bg-white dark:border-darkblue-70 dark:bg-darkmode-20">
              <div className="divide-y divide-darkblue-300/[.14] rounded-b bg-white shadow dark:bg-darkmode-20 p-3">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                {formData?.data?.map((element, i) => (
                  <div key={element.id} className="py-3">
                    {element.type === "date" && element.editable ? (
                      <>
                      <label
                          htmlFor={element.id}
                          className="block text-xs font-medium text-black-100 py-1"
                        >
                          {`${element.label}${element.required ? "*" : ""}`}
                        </label>
                        <ThemeProvider theme={darkTheme}>
                          <DatePicker
                          format="MM/YYYY"
                          value={element.value ? element.value : null}
                          onChange={(newValue) => {
                              handleChange(newValue, element.id, element.type)
                          }}
                          views={['month', 'year']}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: '#6D809F',
                            },

                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: element.id == selectedInput ? "#002983 !important" : (true ? "#2D4059 !important" : "#CCDCE6 !important"),
                              borderWidth: "1px !important"
                          },
                            backgroundColor: "bg-darkblue-100 hover:bg-darkblue-200 hover:border-darkblue-350/[.3] border border-darkblue-200 dark:border-darkblue-70 dark:bg-darkmode-20 dark:text-darkblue-200",
                            width: "100%",
                            input: {
                                color: '#6D809F',
                                fontSize: "16px",
                                fontWeight: 500
                            },
                        }}
                          
                          slotProps={{ textField: { size: "small" } }}
                      />
                        </ThemeProvider>
                      </>
                    ) : element.static ? (
                      <>
                        <label
                          htmlFor={element.id}
                          className="block text-xs font-medium text-black-100 py-1"
                        >
                          {`${element.label}${element.required ? "*" : ""}`}
                        </label>
                        <PlaceholderText
                          contentTextAllign="text-left"
                          opacity={false}
                          description={element.value}
                        />
                      </>
                    ) : !element.editable && !element.static ? (
                      <>
                        {selectedInput === element.id && (
                          <>
                            <DtmfInputField
                              id={element.id}
                              name={element.label}
                              editable={false}
                              required={element.required}
                              value={element.value}
                              valid={element.valid}
                              completed={element.completed}
                              statusText={statusText}
                              onChange={(e) => {
                                handleChange(e.target.value, element.id, element.type)
                              }}
                            />
                            <div className="flex space-x-2">
                              <DtmfRestartButton
                                handleFunction={() => handleRestart(element.id)}
                              />
                              <DtmfConfirmButton
                                active={!selectedInput}
                                handleFunction={() =>
                                  handleComplete(element.id)
                                }
                              />
                            </div>
                          </>
                        )}
                        {selectedInput !== element.id && (
                          <>
                            {!element.completed ? (
                              <DtmfStartProcessButton
                                name={element.label}
                                handleStart={() =>
                                  !selectedInput && handleStart(element.id)
                                }
                                required={element.required}
                                active={!selectedInput}
                              />
                            ) : (
                              <>
                                <DtmfInputField
                                  id={element.id}
                                  name={element.label}
                                  editable={false}
                                  required={element.required}
                                  value={element.value}
                                  valid={element.valid}
                                  completed={element.completed}
                                  statusText={element.validText}
                                  onChange={(e) => {
                                    handleChange(e.target.value, element.id, element.type)
                                  }}
                                />
                                <div className="flex space-x-2">
                                  <DtmfRestartButton
                                    handleFunction={() =>
                                      handleRestart(element.id)
                                    }
                                  />
                                  <DtmfConfirmButton
                                    active={!selectedInput}
                                    handleFunction={() =>
                                      handleComplete(element.id)
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <DtmfInputField 
                        name={element.label}
                        editable={true}
                        value={element.value}
                        required={element.required}
                        onChange={(e) => {
                        handleChange(e.target.value, element.id, element.type)
                      }}/>
                    )}
                  </div>
                ))
                }
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-2 pt-3 pr-4 pl-4 pb-2">
            <Button
              className="flex-1"
              onClick={submitDtmf}
              variant={(buttonDisabled || formData?.data?.some(e => (e.required && !e.value) || (e.valid === false))) ? "disabled" : "default"}
            >
              SUBMIT
            </Button>
          </div>
        </>
      ) : (
        <DTMFSubmitStatus restartDtmf={restartDtmf} />
      )}
          </>
        }
      </Modal>
    </>
  );
};

export default DTMFModal;


