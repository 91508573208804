import { useAppContext } from "@/context/appContext";
import { Modal } from "antd";
import { useEffect } from "react";

const SessionControl = () => {
  const { cookies } = useAppContext();
  useEffect(() => {
    const checkSessionExpiry = () => {
      const currentTime = Date.now();
      const sessionExpiryTime = parseInt(cookies.sessionExpire, 10);
      const oneHour = 60 * 60 * 1000;

      if (sessionExpiryTime - currentTime < oneHour) {
        Modal.warning({
          title: "Session Expiring Soon",
          content:
            "Your session time  will expire in less than one hour please log out all tools and login again",
        });
      }
    };

    checkSessionExpiry();
    const interval = setInterval(checkSessionExpiry, 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return <></>;
};

export default SessionControl;
