import { Layout } from "antd";

import StepsApp from "./StepsApp";
import FormApp from "./FormApp";

export default function ContentApp() {
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <StepsApp />
      <FormApp />
    </Layout>
  );
}
