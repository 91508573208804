const Checked = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="Group_30952"
        data-name="Group 30952"
        transform="translate(-333 -568)"
      >
        <g
          id="Component_164"
          data-name="Component 164"
          transform="translate(333 568)"
        >
          <g
            id="Rectangle_6374"
            data-name="Rectangle 6374"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
            opacity="0"
          >
            <rect width="16" height="16" stroke="none" />
            <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
          </g>
          <path
            id="Union_117"
            data-name="Union 117"
            d="M-31.116,31.616l-2.57-2.571a1.072,1.072,0,0,1,0-1.515,1.063,1.063,0,0,1,.757-.314,1.062,1.062,0,0,1,.758.314l1.814,1.813,5.024-5.024A1.068,1.068,0,0,1-24.6,24h.031a1.071,1.071,0,0,1,.987.653,1.066,1.066,0,0,1-.235,1.181L-29.6,31.615a1.063,1.063,0,0,1-.757.314A1.068,1.068,0,0,1-31.116,31.616Z"
            transform="translate(36.75 -19.965)"
            fill="#1dbe79"
          />
        </g>
      </g>
    </svg>
  );
  
  export default Checked;