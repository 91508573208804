// import * as Sentry from "@sentry/nextjs";
/*global chrome*/

const quickMenuExtensionIdProdNew = process.env.NEXT_PUBLIC_QUICKMENUEXTENSIONPRODID;

export async function SendRequest(eventDetails) {
  try {
    const sendMessagePromiseResponse = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
            quickMenuExtensionIdProdNew,
            eventDetails,(resp) => {
              console.log('resp', resp);
            if (resp.isSuccess) {
                resolve({ ...resp, isSuccess: true});
            } else {
                reject({isSuccess: false});
            }
        });
    });
    return sendMessagePromiseResponse;
  } catch (error) {
    console.log("Send Request function error", error);
    return {
      isSuccess: false,
      error: error
    };
  }
}
