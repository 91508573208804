/*
(function (window) {
    'use strict';
*/
export function BoranChannel1(settingsObject) {
    var _boranChannelObject = {};
    //private
    var logLevels = {
        "debug": 0,
        "info": 1,
        "warn": 2,
        "error": 3,
        "critical": 4
    }

    var settings = {
        "protocol": "ws",
        "host": "",
        "port": 80,
        "logLevel": logLevels.error,
        "autoReconnect": true,
        "reconnectionDelay": 1000, //ms
        "reconnectionBackoffThreshold" : 3,
        "reconnectionDelayBackoff": 2,
        "reconnectionMaxDelay": 30000,
        "onOpen": null,
        "onClose": null,
        "onError": null,
        "onMessage": null,
        "onTokenExpire": null
    }

    for (var i in settingsObject){
        if (i in settings){
            settings[i] = settingsObject[i];
        }
    }

    var reconnectTimeoutInstance = null;
    var reconnectionTryCount = 0;
    var currentReconnectionDelay = settings.reconnectionDelay;
    var socket = null;
    var currentToken = null;



    function boranLog(message, level) {
        if(!level){
            level = logLevels.debug;
        }
        if(level >= settings.logLevel){
            console.log(message);
        }

        if (message === "connected") {
            console.log(message, "to the DTMF Boran socket successfully");
        }
    }

    function reconnect(){
        clearTimeout(reconnectTimeoutInstance);
        reconnectionTryCount++;
        currentReconnectionDelay = reconnectionTryCount < settings.reconnectionBackoffThreshold ?
            settings.reconnectionDelay
            : currentReconnectionDelay *= settings.reconnectionDelayBackoff;

        if (currentReconnectionDelay > settings.reconnectionMaxDelay){
            currentReconnectionDelay = settings.reconnectionMaxDelay;
        }
        //reconnectTimeoutInstance = setTimeout(reconnect, currentReconnectionDelay);
        //boranLog('reconnecting in ' + currentReconnectionDelay + ' milliseconds...');
        connect(currentToken);
    }

    function connect(token){
        currentToken = token;
        var url = settings.protocol + "://" + settings.host + ':' + settings.port + "/ws/" + currentToken;
        socket = new WebSocket(url);

        socket.onopen = function () {
            boranLog("connected");
            reconnectionTryCount = 0;
            currentReconnectionDelay = settings.reconnectionDelay;
            clearTimeout(reconnectTimeoutInstance);

            if(settings.onOpen){
                settings.onOpen()
            }
        }

        boranLog("trying to connecto to websocket at url: " + url);

        socket.onmessage = function(e){
            boranLog(e);
            if (settings.onMessage){
                settings.onMessage(e);
            }
        }

        socket.onclose = function(e){
            boranLog("socket closed!");
            boranLog(e);
            console.log(e?.reason);
            console.error(e);
            if (settings.onClose){
                settings.onClose(e);
            }
            switch (e.code){
                case 4003: {
                    // wrong or expired token
                    boranLog("token expired!", logLevels.warn)
                    if (settings.onTokenExpire){
                        settings.onTokenExpire();
                    }
                    break;
                }
                default:{
                    if(settings.autoReconnect) {
                        boranLog('reconnecting in ' + currentReconnectionDelay + ' milliseconds...');
                        reconnectTimeoutInstance = setTimeout(reconnect, currentReconnectionDelay);
                    }
                }
            }
        }

        socket.onerror = function(e){
            boranLog(e, logLevels.error);
            console.error(e);
            if (settings.onError){
                settings.onError(e);
            }
        }
    }

    function increaseLogLevel(){
        if(settings.logLevel >= 4)
            return;
        settings.logLevel++;
    }

    function decreaseLogLevel(){
        if(settings.logLevel <= 0)
            return;
        settings.logLevel--;
    }

    function getLogLevel(){
        return settings.logLevel;
    }

    _boranChannelObject.connect = connect;
    _boranChannelObject.increaseLogLevel = increaseLogLevel;
    _boranChannelObject.decreaseLogLevel = decreaseLogLevel;
    _boranChannelObject.getLogLevel = getLogLevel;

    _boranChannelObject.getCurrentURL = function(){
        return socket != null ? socket.url : "";
    }

    return _boranChannelObject;
}


/*
    // We need that our library is globally accesible, then we save in the window
    if (typeof(window.BoranChannel) === 'undefined') {
        window.BoranChannel = BoranChannel;
    }
})(window);*/
