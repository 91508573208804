const RestartProcess = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_30949"
        data-name="Group 30949"
        transform="translate(-127 -642)"
      >
        <g
          id="Component_583_8"
          data-name="Component 583 – 8"
          transform="translate(127 642)"
        >
          <g
            id="Rectangle_7938"
            data-name="Rectangle 7938"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
            opacity="0"
          >
            <rect width="24" height="24" stroke="none" />
            <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
          </g>
          <path
            id="Subtraction_295"
            data-name="Subtraction 295"
            d="M-9416.968-1830.6a7.941,7.941,0,0,1-7.934-7.931,7.942,7.942,0,0,1,7.934-7.933,7.838,7.838,0,0,1,5.672,2.4l.088.09v-2.1a.824.824,0,0,1,.823-.823.825.825,0,0,1,.825.823v4.146a.825.825,0,0,1-.825.823h-4.145a.825.825,0,0,1-.825-.823.826.826,0,0,1,.825-.826h2.205l-.078-.083a6.2,6.2,0,0,0-4.565-1.975,6.29,6.29,0,0,0-6.282,6.282,6.29,6.29,0,0,0,6.282,6.282,6.077,6.077,0,0,0,4.019-1.49,6.789,6.789,0,0,0,2.205-3.931.826.826,0,0,1,.809-.669.872.872,0,0,1,.156.014.812.812,0,0,1,.527.345.822.822,0,0,1,.128.62A8.037,8.037,0,0,1-9416.968-1830.6Z"
            transform="translate(9429.005 1850.75)"
            fill="#6d809f"
          />
        </g>
      </g>
    </svg>
  );
  
  export default RestartProcess;