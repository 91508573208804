import { useAppContext } from "@/context/appContext";
import { createBoranChannel } from "@/utils/ws";
import { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import axios from "axios";

const DTMFBoranNotification = () => {
  const {
    cookies: { tenant, session, email },
    dtmfState,
    setDtmfState,
    messageData,
    setShowDTMFButton,
    setOpenDTMFModal,
  } = useAppContext();
  const [getMySipInfo, setGetMySipInfo] = useState(null);

  useEffect(() => {
    if (messageData?.type === "connected") {
      setShowDTMFButton(true);
    } else if (messageData?.type === "disconnected") {
      setShowDTMFButton(false);
      setOpenDTMFModal(false);
    } else if (messageData?.type === "destroyed") {
      setDtmfState(false);
      setOpenDTMFModal(false);
    }
  }, [messageData, getMySipInfo]);

  function onMessage(e) {
    let m = e?.data?.split("|");
    if (m?.[0] === "dtmf_input") {
      let inputAck = m[1];
      let variable_name = m[2];
      let value = m[3];
      let tmpDtmfState;

      flushSync(() => {
        setDtmfState((prev) => {
          tmpDtmfState = prev;
          return prev;
        });
      });

      let newDtmfData = tmpDtmfState.data;
      let variableIndex = newDtmfData.findIndex((e) => e.id == variable_name);

      if (variableIndex == -1) return;
      if (variableIndex != -1) {
        if (
          newDtmfData[variableIndex].type == "date" &&
          !newDtmfData[variableIndex].editable &&
          newDtmfData[variableIndex]?.value?.length >= 2
        ) {
          let valueArray = value.split("");
          valueArray.splice(2, 0, " / ");
          newDtmfData[variableIndex].value = valueArray.join("");
        } else {
          newDtmfData[variableIndex].value = value;
        }
      }

      flushSync(() => {
        setDtmfState((prev) => ({
          ...prev,
          data: newDtmfData,
        }));
      });
    } 
  }

  useEffect(() => {
    createBoranChannel(false, null, onMessage, tenant, session, email);
    axios({
      method: "POST",
      url: `/api/getMySipInfo`,
      data: {
        session: session,
      },
    })
      .then((response) => {
        setGetMySipInfo(response.data);
      })
      .catch((err) => {
        console.log(err);
        axios({
          method: "POST",
          url: "/api/gcpLogger",
          data: {
            gcpData: {
              success: false,
              data: err?.response?.data,
              url: err?.config?.url,
              status: err?.response?.status || 408,
              message: err?.message,
              agentEmail: email,
            },
            message: `productAPI/getMySipInfo`,
          },
        });
      });
  }, []);

  return <></>;
};

export default DTMFBoranNotification;
