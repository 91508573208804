import { useEffect } from "react";

import Head from "next/head";
import { Layout, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "../styles/Home.module.css";

import { useAppContext } from "../context/appContext";
import { PushNotificationApp } from "../components/PushNotificationApp";
import HeaderApp from "../components/HeaderApp";
import ContentApp from "../components/ContentApp";
import SignInApp from "../components/SignInApp";
import Service from "../public/js/checkLogin";
import DTMFBoranNotification from "@/components/DTMF/DTMFBoranNotification";
import DTMFModal from "@/components/DTMF/DTMFModal";
import SessionControl from "@/components/DTMF/SessionControl";

export default function Index() {
  const { setIsAuth, isAuth, setIsLoadingPage, isLoadingPage, cookies, openDTMFModal } =
    useAppContext();

  useEffect(() => {
    if (!(cookies.session && cookies.tenant)) {
      setIsAuth(false);
      setIsLoadingPage(false);
    }

    async function fetchData() {
      try {
        var checkLoginResp = await Service.CheckLogin(
          cookies.session,
          cookies.tenant
        );

        if (!(checkLoginResp.login && cookies.email)) throw new Error("Session wrong or expired");
        setIsLoadingPage(false);
        setIsAuth(true);
      } catch (error) {
        setIsLoadingPage(false);
        setIsAuth(false);
      }
      if (isAuth) {
        console.log("It has been authanticated");
      }
    }
    fetchData();
  }, [isAuth]);

  return !isLoadingPage ? (
    !isAuth ? (
      <SignInApp />
    ) : (
      <>
        <Head>
          <title>CCS Order Management</title>
          <meta name="description" content="Generated by Call Center Studio" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <PushNotificationApp />
        <DTMFBoranNotification />
        <SessionControl />
        <Layout style={{backgroundColor: "#fff"}}>
          <HeaderApp />
          <ContentApp />
          {
            openDTMFModal && <DTMFModal />
          }
        </Layout>
      </>
    )
  ) : (
    <div className={styles.spin}>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 32,
              marginBottom: "1vh",
            }}
            spin
          />
        }
      />
      <h4>Loading</h4>
    </div>
  );
}
