import { useEffect, useState } from "react";
import { Button } from "@/components/DTMF/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/DTMF/tooltip";
import Checked from "@/components/DTMF/icons/Checked"
import Danger from "@/components/DTMF/icons/Danger"

const DtmfInputField = ({
    name,
    id,
    statusText,
    editable = true,
    value,
    required,
    valid,
    onChange,
  }) => {
    const [inputValue, setInputValue] = useState("");
  
    const handleChange = (e) => {
      setInputValue(e.target.value);
      onChange(e);
    };
  
    useEffect(() => {
      if (!(value == undefined || value == null)) {
        setInputValue(value);
      }
    }, [editable, value]);
  
    return (
      <div className="w-full mx-auto mb-3">
        <div className="flex items-center justify-between">
          <label
            htmlFor={id}
            className="block text-xs font-medium text-black-100 py-1"
          >
            {`${name}${required ? "*" : ""}`}
          </label>
          <div className="flex items-center justify-between"> 
            {
              id === "creditCardNumberInfo" && (
                <span className="text-sm" >
                  {inputValue.length}
                </span>
              )
            }
          {valid != null && (
            <TooltipProvider delayDuration="100">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="link" className="h-auto p-0">
                    {valid ? <Checked /> : <Danger />}
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  <p
                    className={`text-xs font-normal ${
                      valid ? "text-meadow-500" : "text-red-600"
                    }`}
                  >
                    {statusText}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <input
            type="text"
            id={id}
            name={name}
            value={inputValue}
            onChange={(e) => {
              handleChange(e);
            }}
            readOnly={!editable}
            className="flex-1 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-darkblue-70 dark:bg-darkmode-20 dark:text-darkblue-200"
          />
        </div>
      </div>
    );
  };

export default DtmfInputField