import axios from "axios";
import { BoranChannel1 } from "@/utils/boran";

var boranChannel = null;
var getBoranTokeTimeoutId = null;
var BoranChannel = null
var tenantNameListForSocket = [];


BoranChannel = BoranChannel1

export function createBoranChannel(supervisor=false, supervisorCallback=null, callBack=null, tenant, session, agentEmail) {
    boranChannel = new BoranChannel(
        {
            "protocol": "wss",
            "host": "boran-channel.alo-tech.com",
            "port": 443,
            "logLevel": 3,
            "supervisor": supervisor,
            "reconnectionDelay": 1, // 1 second
            "reconnectionDelayBackoff": 5,
            "reconnectionMaxDelay": 600, // 10 minutes
            "onMessage": supervisor == true ? onSupervisorMessage : onBoranMessage,
            "onTokenExpire": onBoranTokenExpire
        }
    );

    function onSupervisorMessage(e) {
        supervisorCallback(e.data);
    }

    function onBoranMessage(e){
        callBack(e);
    }

    function onBoranTokenExpire() {
        getBoranToken();
    }

    function getBoranToken() {
        axios({
            method: "GET",
            url: `https://${tenant}/api/?function=get_boran_token`,
            params: {
                supervisor: supervisor.toString(),
                session: session
            }
        }).then(response => {
            if (response.data.success) {
                boranChannel.connect(response.data.boran.token);
            } else {
                if (getBoranTokeTimeoutId) {
                    clearTimeout(getBoranTokeTimeoutId);
                }
                if (response.data.nouser) {
                    return false;
                }
                var getBoranTokeTimeoutId = setTimeout(getBoranToken, 5000);
            }
        }).catch(err => {
            if (getBoranTokeTimeoutId) {
                clearTimeout(getBoranTokeTimeoutId);
            }
            var getBoranTokeTimeoutId = setTimeout(getBoranToken, 5000);
            console.error(err);
            axios({
                method: "POST",
                url: "/api/gcpLogger",
                data: {
                  gcpData: {
                    success: false,
                    data: err?.response?.data,
                    url: err?.config?.url,
                    status: err?.response?.status || 408,
                    message: err?.message,
                    agentEmail: agentEmail
                  },
                  message: `productAPI/getBoranToken`,
                },
            });
        })
    }
    getBoranToken();

}
//******************************


export function createchannel(onChannelMessage) {
    createBoranChannel(false,null,() => onChannelMessage);
}
/*
    function onChannelMessage(message) {
        let m = message.split('|');
        switch (m[0]) {

            case "report":

                console.log(m[1]);
                window.location.reload();
                break;
            case "report-fail":
                window.location.reload();
                //call activeReports with Id
                break;
        }
    }
*/