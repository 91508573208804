const StartProcess = ({ active, ...props }) => (
    <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_30944"
      data-name="Group 30944"
      transform="translate(-127 -642)"
    >
      <g id="Play" transform="translate(127 642)">
        <g
          id="Rectangle_6232"
          data-name="Rectangle 6232"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          id="Subtraction_193"
          data-name="Subtraction 193"
          d="M1.859,14.4A1.845,1.845,0,0,1,0,12.572V1.828A1.863,1.863,0,0,1,1.786,0h.069a1.8,1.8,0,0,1,.867.224L12.417,5.6a1.851,1.851,0,0,1,0,3.208L2.722,14.176A1.769,1.769,0,0,1,1.859,14.4ZM1.812,1.563a.236.236,0,0,0-.117.03.26.26,0,0,0-.119.235V12.572a.24.24,0,0,0,.235.266.309.309,0,0,0,.148-.041l9.7-5.372a.244.244,0,0,0,0-.45L1.958,1.6A.3.3,0,0,0,1.812,1.563Z"
          transform="translate(5.8 4.8)"
          fill={active ? "#fd581f" : "#6d809f"}
        />
      </g>
    </g>
  </svg>
  );
  
  export default StartProcess;