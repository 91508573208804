const axios = require('axios');

/**
 * @description check login
 * @param session session value
*/
module.exports.CheckLogin = async (session, tenant) => {
    try {
        if (session && tenant) {
            var response = await axios({
                url: `https://${tenant}/api/`,
                method: "GET",
                headers: {},
                data: {},
                params: {
                    function: "checklogin",
                    session: session
                },
                timeout: 5000
            });

            return response.data;
        }
        return false
    } catch (error) {
        return error.message;
    }
};