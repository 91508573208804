import axios from "axios";
import { Button, Form, Input, Spin, notification } from "antd";
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";

import { useAppContext } from "../context/appContext";
import Config from "../config/index";
import styles from "../styles/SignIn.module.css";
import OTPVerificationModal from "./OTPVerificationModal";
import { useState } from "react";

const tenant = Config.tenant;

const loadingIcon = (
  <LoadingOutlined
    style={{
      fontSize: 32,
    }}
    spin
  />
);

const openNotification = (message) => {
  notification.open({
    message: "Warning",
    description: message ? message : "Opss! Unexpected situation has occurred!",
    className: "custom-class",
    style: {
      width: 600,
    },
  });
};

export default function SignInApp() {
  const { setIsAuth, setCookie } =
    useAppContext();

  const [loginMessage, setLoginMessage] = useState("");
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otpMethod, setOtpMethod] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [resendTimeout, setResendTimeout] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleValuesChange = (changedValues, allValues) => {
    setFormData(allValues);
  };

  const handleLogin = async (otp) => {
    setConfirmLoading(true);
    if(typeof(otp) !== "string"){
      setIsLoading(true); 
    }
    try {
      const response = await axios({
        url: `/api/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: formData.username,
          password: formData.password,
          otp: otp
        },
        timeout: 5000,
      });
      const userSession = response?.data?.data?.session;
      if (response.status == 200 && userSession) {
        const privilidgeResponse = await axios({
          url: "/api/userPrivilegesContol/",
          method: "POST",
          data: {
            session: userSession,
            tenant: tenant,
          },
        });
        if (
          privilidgeResponse.data.data.login &&
          privilidgeResponse.data.data.active
        ) {
          setCookie("session", userSession, { maxAge: 86400 });
          setCookie("email", formData.username, { maxAge: 86400 });
          setCookie("tenant", tenant, { maxAge: 86400 });
          setCookie("sessionExpire",response.data?.sessionExpireValue, { maxAge: 86400 });
          setIsLoading(false);
          setIsAuth(true);
          setConfirmLoading(false)
        } else {
          setIsLoading(false);
          setIsAuth(false);
          openNotification(response.data.message);
        }
      } else {
        setIsLoading(false);
        setIsAuth(false);
        openNotification(response.data.message);
      }
    } catch (err) {
      setIsLoading(false);
      setIsAuth(false);
      setConfirmLoading(false);
      openNotification(err.response ? err.response.data?.data?.message : err.message);
      console.log(err.message);
    }
  };

  const handleCheckLogin = async function ({ username, password }) {
    setIsLoading(true);
    try {
      const response = await axios({
        url: "https://eperformax-arria.callcenterstudio.com/api/",
        method: "GET",
        params: {
          email: formData.username,
          password: formData.password,
          function: "login_check",
        },
        timeout: 5000,
      })

      if (response?.data?.renew && response?.data?.user_key) {
        const userKey = response?.data?.user_key;
        const renewUrl = `https://eperformax-arria.callcenterstudio.com/login#/renew-password?user_key=${userKey}`;

        const handleButtonClick = () => {
          window.open(renewUrl, '_blank');
        };
        notification.open({
          message: "Your Password Has Expired",
          description: (
            <div>
              <p>For security reasons, you need to update your password. Please click the "Renew Password" button below to proceed.</p>
              <button
                style={{
                  background: "red",
                  color: "white",
                  border: "none",
                  padding: "8px 16px",
                  cursor: "pointer",
                  marginTop: "10px"
                }}
                onClick={handleButtonClick}
              >
                Renew Password
              </button>
            </div>
          ),          style: {
            width: 600,
            background: "orange"
          },
          duration: 0,
          placement: "topLeft",
        });
      }


        if (response.status == 200 && response.data.login_check) {
          if (response?.data?.email_id) {
           setTimeout(async () => {
            const emailId = response?.data?.email_id
            const otpEmailSendCheck = await axios({
              url: `https://eperformax-arria.callcenterstudio.com/auth/otp_delivery_status/${emailId}/`,
              method: "GET",
              timeout: 5000,
            })

            if (otpEmailSendCheck?.data?.result === false) {
              notification.open({
                message: "Error",
                description: otpEmailSendCheck?.data?.message || "We were unable to deliver your OTP code via email. Please reach out to your supervisor to obtain your OTP",
                style: {
                  width: 600,
                  background: "orange"
                },
                duration: 0,
                placement: "topLeft",
              });
            }
           }, 5000)
          }

        setOtpMethod(response.data.otp_method);
        setLoginMessage("");
        if (!response.data.otp) {
          handleLogin();
        } else {
          setResendTimeout(response?.data?.remaining_time || 180)
          setIsLoading(false);
          setIsOtpModalOpen(true);
        }
      } else {
        setIsLoading(false);
        setLoginMessage(response.data.message);
        openNotification(response.data.message);
      }
    } catch (err) {
      openNotification(err.response ? err.response.data.message : err.message);
      console.log(err.message);
    }
  }
  return isLoading ? (
    <Spin indicator={loadingIcon} className={styles.spin} />
  ) : (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <div className={styles.brandLogo}>
          <img src="images/ccs-logo.svg" />
          <p>
            <i>Welcome back..</i>
          </p>
        </div>

        <Form
          name="normal_login"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={handleCheckLogin}
          onValuesChange={handleValuesChange}
          style={{ width: "330px" }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
            style={{ marginBottom: "16px" }}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
            style={{ marginBottom: "16px" }}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: "16px" }}>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.submitFormButton}
              block
            >
              Sign In
            </Button>
          </Form.Item>
          <br/>
        </Form>
        <OTPVerificationModal
              username={formData.username}
              isOpen={isOtpModalOpen}
              confirmLoading={confirmLoading}
              onClose={() => setIsOtpModalOpen(false)}
              onVerify={handleLogin}
              resendTimeout={resendTimeout}
              otpMethod= {otpMethod}
              openNotification={openNotification}
          />
      </div>
    </div>
  );
}
