import { forwardRef } from "react"
import { cva } from "class-variance-authority"

import { cn } from "@/utils/cn"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-bold transition-colors focus:outline-none " +
    "dark:hover:bg-orange-600 dark:hover:text-white data-[state=open]:bg-slate-100 dark:data-[state=open]:bg-slate-800",
  {
    variants: {
      variant: {
        default:
          "bg-orange-500 hover:bg-orange-600 text-white dark:bg-orange-500 dark:text-white",
        disabled:
          "pointer-events-none border border-darkblue-70 dark:border-darkblue-70 text-darkblue-200 dark:text-darkblue-350 dark:border-darkblue-350 pointer-events-none",
        outline:
          "bg-darkblue-100 hover:bg-darkblue-200 hover:border-darkblue-350/[.3] border border-darkblue-200 dark:border-darkblue-200 dark:text-darkblue-200 text-darkblue-350 dark:bg-darkblue-100 dark:hover:bg-darkblue-100/[.25]",
        "outline-orange":
          "bg-white hover:bg-darkblue-200 hover:bg-orange-500 hover:text-white hover:border-orange-500 border border-orange-500 bg-white dark:border-orange-500 text-orange-500 dark:hover:bg-darkblue-800 dark:bg-darkmode-50",
        white:
          "bg-white hover:border-darkblue-350/[.3] border border-darkblue-200 dark:bg-darkmode-20 dark:border-darkblue-350 text-darkblue-200 dark:hover:bg-darkmode-20 dark:hover:text-darkblue-300",
        accept:
          "bg-meadow-500 text-white hover:bg-meadow-600 dark:bg-meadow-400 dark:text-slate-900 dark:hover:bg-meadow-500 dark:text-white",
        decline: "bg-red-600 text-white hover:bg-red-700 dark:hover:bg-red-700",
        "outline-decline":
          "bg-white text-red-600 border border-red-600 hover:bg-red-600 hover:text-white dark:hover:bg-red-700 dark:bg-darkmode-20",
        ghost:
          "bg-transparent hover:bg-slate-100 dark:hover:bg-slate-800 dark:text-slate-100 dark:hover:text-slate-100 data-[state=open]:bg-transparent dark:data-[state=open]:bg-transparent",
        link: "bg-transparent dark:bg-transparent underline-offset-4 hover:underline text-slate-900 dark:text-slate-100 hover:bg-transparent dark:hover:bg-transparent",
      },
      size: {
        default: "h-10 py-2 px-4",
        sm: "h-9 px-2 rounded-md",
        lg: "h-12 py-2 px-4",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

const Button = forwardRef(({ className, variant, size, ...props }, ref) => {
  return (
    <button
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...props}
    />
  )
})
Button.displayName = "Button"

export { Button, buttonVariants }
