const ConfirmProcess = ({active, ...props}) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_30951"
        data-name="Group 30951"
        transform="translate(-126.636)"
      >
        <g
          id="Component_335_2"
          data-name="Component 335 – 2"
          transform="translate(126.636)"
        >
          <g
            id="Rectangle_6374"
            data-name="Rectangle 6374"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
            opacity="0"
          >
            <rect width="24" height="24" stroke="none" />
            <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
          </g>
          <path
            id="Subtraction_108"
            data-name="Subtraction 108"
            d="M9829.856,15928.193a1.2,1.2,0,0,1-.852-.352l-3.3-3.306a1.171,1.171,0,0,1-.353-.848,1.2,1.2,0,0,1,2.051-.852l2.455,2.454,6.584-6.584a1.181,1.181,0,0,1,.819-.355h.036a1.2,1.2,0,0,1,.843,2.059l-7.431,7.432A1.2,1.2,0,0,1,9829.856,15928.193Z"
            transform="translate(-9819.926 -15911.273)"
            fill={active ? "#fd581f" : "#6d809f"}
          />
        </g>
      </g>
    </svg>
  );
  
  export default ConfirmProcess;